import React, { useCallback, useMemo } from 'react';
import { styled, media } from 'styles';
import Select from 'react-select';
import { useBookExerciseTracker } from 'modules/books/hooks';
import { useBookEditionContext } from '../providers/BookEditionProvider';
import useBookSelector from '../hooks/useBookSelector';

export default function BookExerciseSelector() {
  const {
    setCurrentSecttionId,
    currentExerciseId,
    currentSectionId,
    onChangeExerciseSelected,
  } = useBookEditionContext();
  const { trackSectionClickOnSelector } = useBookExerciseTracker();
  const { exerciseOptions, sectionOptions } = useBookSelector();

  const isBookQuestionSelected = useCallback(() => {
    return currentExerciseId !== -1;
  }, [currentExerciseId]);

  const onSectionSelect = useCallback(
    option => {
      trackSectionClickOnSelector(option.value);
      setCurrentSecttionId(option.value);
      if (currentExerciseId !== -1) onChangeExerciseSelected(-1);
    },
    [
      setCurrentSecttionId,
      currentExerciseId,
      onChangeExerciseSelected,
      trackSectionClickOnSelector,
    ]
  );

  const currentSectionValueInSelector = useMemo(() => {
    return sectionOptions.find(section => section.value === currentSectionId);
  }, [currentSectionId, sectionOptions]);

  return (
    <>
      {!isBookQuestionSelected() && (
        <>
          <SelectorTitle>Já está com seu livro aberto aí?</SelectorTitle>
          <PageSubtitle>
            Selecione o capítulo e a questão abaixo para ver a solução passo a
            passo
          </PageSubtitle>
        </>
      )}

      <SelectContainer data-cy="selector-container" className="new">
        <Select
          value={currentSectionValueInSelector}
          options={sectionOptions}
          onChange={onSectionSelect}
          styles={customSelectStyle('Capítulo')}
          placeholder={'Escolha'}
        />

        {currentSectionId !== -1 && (
          <Select
            value={
              exerciseOptions.find(opt => opt.value === currentExerciseId) ||
              null
            }
            options={exerciseOptions}
            onChange={option => onChangeExerciseSelected(option.value)}
            styles={customSelectStyle('Questão')}
            placeholder={'Escolha'}
          />
        )}
      </SelectContainer>
    </>
  );
}

const customSelectStyle = placeholder => ({
  control: provided => ({
    ...provided,
    borderRadius: '30px',
    border: 'none',
    background: '#14B9A6',
    boxShadow: 'none',
    padding: '10px 15px',
  }),
  container: provided => ({
    ...provided,
    position: 'relative',
    width: '220px',
    maxWidth: '100%',
    height: '50px',
    color: 'white',
    margin: '20px',
  }),
  option: (provided, { data }) => ({
    ...provided,
    color: '#14B9A6',
    margin: 'auto auto 5px auto',
    backgroundColor: 'rgba(0,0,0,.1)',
    width: '90%',
    padding: '3px',
    textAlign: 'center',
    '&::after': data.isFree && {
      content: `"GRÁTIS"`,
      fontSize: '12px',
      fontWeight: 'bold',
      paddingLeft: '3px',
      paddingRight: '3px',
      paddingTop: '2px',
      paddingBottom: '2px',
      color: 'white',
      backgroundColor: '#F7AC3B',
      display: 'block',
      width: '97%',
      margin: '2px auto 2px auto',
    },

    '&:hover': {
      color: '#FFF',
      backgroundColor: '#14B9A6',
    },
  }),
  placeholder: provided => ({
    ...provided,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.1em',
    '&::before': {
      content: `"${placeholder}: "`,
      fontWeight: 'normal',
      marginRight: '5px',
      fontSize: '.85em',
    },
  }),
  singleValue: provided => ({
    ...provided,
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.1em',
    '&::before': {
      content: `"${placeholder}: "`,
      fontWeight: 'normal',
      marginRight: '5px',
      fontSize: '.85em',
    },
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: '#FFF!important',
  }),
  indicatorSeparator: provided => ({
    ...provided,
    backgroundColor: '#FFF',
  }),
});

const SelectContainer = styled.div`
  display: flex;
  margin: 20px;

  &.new {
    margin-left: 0px;
  }

  @media ${media.mobile} {
    flex-direction: column;
  }
`;

const SelectorTitle = styled.h3`
  font-size: 1.8em;
  color: #14b9a6;
  font-weight: bold;
  margin-top: 50px;
  text-align: center;

  @media ${media.mobile} {
    margin-top: 20px;
  }
`;

const PageSubtitle = styled.span`
  font-size: 1em;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 20px;
  color: #888;
  text-align: center;

  @media ${media.mobile} {
    margin-bottom: 0px;
  }
`;
