import React, { useCallback, useState, useRef } from 'react';
import { css } from 'styled-components';
import { Link, useLocation } from 'react-router-dom';

import { styled, media } from 'styles';
import { useDialogContext } from 'providers/DialogProvider';
import { useUserContext } from 'providers/UserProvider';
import { DownloadAppDialog } from 'modules/shared/dialogs';
import { useHeader, useOutsideClick } from 'modules/shared/hooks';
import { NotificationList } from 'modules/shared/components/header';
import { Notifications } from '@material-ui/icons';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible';
import { UploadBookListDialog } from 'modules/campaigns/mvp-books/dialogs/UploadBookListDialog';

const HeaderMenu = () => {
  const { user } = useUserContext();
  const location = useLocation();
  const { setDialog } = useDialogContext();
  const { getMenuOptionByPath } = useHeader();
  const headerRef = useRef(null);
  const [showNotification] = useState(false);
  const { shouldShowSendListInHeader } = useMvpBooksEligible();

  const [isNotificationOpen, setIsNotificationOpen] = useState(false);

  useOutsideClick(headerRef, () => setIsNotificationOpen(false));

  const toggleNotificationList = useCallback(() => {
    setIsNotificationOpen(!isNotificationOpen);
  }, [isNotificationOpen, setIsNotificationOpen]);

  const shouldShowPromoTag = useCallback(() => {
    const targetDate = new Date(2025, 0, 20, 19, 0, 0); // January 20, 2025, at 19:00
    const now = new Date();

    const allowedUniversityIds = [
      338,
      507,
      233,
      639,
      666,
      172,
      339,
      130,
      665,
      371,
      49,
      505,
      106,
      147,
      148,
      548,
      483,
      237,
      325,
      231,
      195,
      570,
      667,
      571,
      324,
      372,
      26,
      215,
      1803,
      301,
      782,
      547,
      225,
      238,
      235,
      232,
      206,
      509,
      236,
      664,
      105,
      47,
      637,
      234,
      51,
      50,
      239,
      15,
      226,
      194,
      171,
      340,
      800,
      240,
      484,
      572,
      173,
      564,
      373,
      503,
      302,
      569,
      638,
      566,
      799,
      323,
      2720,
      5,
      136,
      25,
      213,
      567,
      609,
      341,
      546,
      2709,
      504,
      129,
      629,
      2737,
      214,
      14,
      303,
      205,
      230,
      610,
      1721,
      213,
      510,
      2501,
      300,
      20,
      341,
      783,
      375,
      565,
      1,
      228,
      568,
      19,
      229,
      48,
      107,
      146,
      227,
      216,
      2738,
      630,
      2787,
      2662,
    ];

    return (
      user &&
      !user.subscriber &&
      now > targetDate &&
      user.university?.id &&
      allowedUniversityIds.includes(user.university.id)
    );
  }, [user]);

  const openDownloadAppDialog = useCallback(() => {
    setDialog(<DownloadAppDialog />);
  }, [setDialog]);

  const openUploadListDialog = useCallback(() => {
    const utms = {
      utmSource: 'site_upload',
      utmMedium: 'botao_enviar_lista',
      utmContent: 'header',
    };
    setDialog(<UploadBookListDialog origin="header" utms={utms} />);
  }, [setDialog]);

  const RESPONDEAI_URL = process.env.REACT_APP_RESPONDEAI_URL;

  const menuOptionName = getMenuOptionByPath(location.pathname);
  return (
    <Container ref={headerRef}>
      <ButtonsContainer>
        <MenuButton
          to={'/materias'}
          className={menuOptionName === 'themes' ? 'current' : ''}
        >
          Matérias
        </MenuButton>
        <MenuButton
          to="/auloes"
          className={menuOptionName === 'lectures' ? 'current' : ''}
        >
          Aulões
        </MenuButton>
        <MenuButton
          to="/livros-resolvidos"
          className={menuOptionName === 'books' ? 'current' : ''}
        >
          Livros
          <br />
          Resolvidos
        </MenuButton>
        <MenuButton
          to={'/resumoes-gratis'}
          className={menuOptionName === 'summaries' ? 'current' : ''}
        >
          Resumões
          <br />
          Grátis
        </MenuButton>
        <DialogButton onClick={openDownloadAppDialog}>
          {' '}
          Baixe o app{' '}
        </DialogButton>
        {shouldShowPromoTag() ? (
          <ExternalLink
            href="https://www.respondeai.com.br/planos-iag-25-1?internal_source=promo"
            target="_blank"
          >
            <PromoStrip>Promo</PromoStrip>
            Planos
          </ExternalLink>
        ) : (
          <ExternalLink href={`${RESPONDEAI_URL}/planos`}>Planos</ExternalLink>
        )}
        <ExternalLink
          href={`https://app.zazos.com/t/driven/p/826aee3b-800f-4a00-9ec8-16bf9f0e7da8`}
        >
          Junte-se a nós
        </ExternalLink>
        <ExternalLink href={`${RESPONDEAI_URL}/faq`}>FAQ</ExternalLink>

        {user && !user.subscriber && showNotification && (
          <NotificationButton>
            <NotificationIcon onClick={toggleNotificationList} />
            <UnreadMark />
            <NotificationList
              isOpen={isNotificationOpen}
              toggleNotificationList={toggleNotificationList}
            />
          </NotificationButton>
        )}
      </ButtonsContainer>
    </Container>
  );
};

const NotificationButton = styled.div`
  padding: 2px;
  cursor: pointer;
  position: relative;
`;

const NotificationIcon = styled(Notifications)`
  margin-left: 25px;
`;

const UnreadMark = styled.div`
  background-color: red;
  width: 9px;
  height: 9px;
  position: absolute;
  top: -1px;
  left: 44px;

  border-radius: 18px;
`;

const Container = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media ${media.mobile} {
    display: none;
  }
`;

const linksStyle = css`
  font-size: 0.75em;
  text-transform: uppercase;
  padding: 0 6px;
  height: 44px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  transition: background-color 100ms ease-in;
  color: #666;
  text-align: center;
  position: relative;
  margin: 0 6px;
  cursor: pointer;

  &.current {
    color: #f7ac3b;
    font-weight: bold;

    ::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background: #f7ac3b;
      position: absolute;
      bottom: -5px;
      left: 0;
    }
  }

  &.subscribe {
    color: #f7ac3b;
    font-weight: bold;
    white-space: nowrap;
    justify-content: flex-end;
  }

  &:hover {
    background: #f2f3f5;
  }
`;

const ExternalLink = styled.a`
  position: relative;
  ${linksStyle}
  span {
    font-size: 15px;
  }
`;

const MenuButton = styled(Link)`
  ${linksStyle}
`;

const ButtonsContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
`;

const DialogButton = styled.p`
  ${linksStyle}
`;

const PromoStrip = styled.div`
  position: absolute;
  top: 6px;
  left: -13px;
  transform: rotate(-43deg);
  padding: 1px 3px;
  background-color: red;
  color: #fff;
  font-size: 0.85em;
  font-weight: bold;
`;

export default HeaderMenu;
