import React, { useCallback, useState } from 'react';
import { useProfileComplete } from 'modules/profile/hooks';
import {
  Button,
  CenterContainer,
  MainContainer,
  UniversityFlag,
} from './styles';
import TermsAndContactCheckbox from './components/TermsAndContactCheckbox';
import UniversitySelects from './components/UniversitySelects';
import ErrorMessage from './components/ErrorMessage';
import Header from './components/Header';

export default function IsOnUniversityForm({ afterSubmit, onBack }) {
  const [saving, setSaving] = useState(false);
  const [acceptedTermsAndContact, setAcceptedTermsAndContact] = useState(false);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedCampus, setSelectedCampus] = useState(null);
  const [campi, setCampi] = useState([]);
  const [lastInput, setLastInput] = useState('');
  const [error, setError] = useState('');

  const { submitProfileComplete } = useProfileComplete();

  const canSendForm = useCallback(() => {
    let error = '';
    if (!acceptedTermsAndContact) {
      error = 'Você precisa aceitar os termos de uso para continuar';
    }
    if (!selectedUniversity) {
      error = 'Você precisa selecionar uma universidade';
    }

    if (campi.length > 0 && !selectedCampus) {
      error = 'Você precisa selecionar um campus';
    }

    setError(error);

    setTimeout(() => {
      setError('');
    }, 3000);

    return error === '';
  }, [
    acceptedTermsAndContact,
    setError,
    selectedUniversity,
    campi,
    selectedCampus,
  ]);

  const updateProfile = useCallback(async () => {
    if (saving) return;

    if (!canSendForm()) return;

    let params = {
      contact_preferences: acceptedTermsAndContact,
      university_id: selectedUniversity?.value,
      university_campus_id: selectedCampus?.value,
      last_input: lastInput,
      from: 'profile complete',
    };

    setSaving(true);
    const { data, error } = await submitProfileComplete(
      params,
      selectedUniversity
    );

    if (data?.ok) afterSubmit(selectedUniversity);
    else if (error) {
      setError('Houve um erro ao salvar o perfil. Tente novamente.');
      setTimeout(() => {
        setError('');
      }, 3000);
    }
    setSaving(false);
  }, [
    acceptedTermsAndContact,
    selectedUniversity,
    selectedCampus,
    lastInput,
    canSendForm,
    saving,
    submitProfileComplete,
    setError,
    afterSubmit,
  ]);

  return (
    <MainContainer>
      <Header
        copy={
          'Complete o cadastro e veja o que preparamos especialmente para a sua universidade.'
        }
      />

      <CenterContainer>
        <ErrorMessage error={error} />
        <UniversityFlag>
          <span>FAÇO FACULDADE</span>
          <span onClick={onBack}>ALTERAR</span>
        </UniversityFlag>
        <UniversitySelects
          onChangeSelectedUniversity={setSelectedUniversity}
          onChangeSelectedCampus={setSelectedCampus}
          onChangeCampi={setCampi}
          setLastInput={setLastInput}
        />
        <>
          <TermsAndContactCheckbox onChange={setAcceptedTermsAndContact} />
          <Button onClick={updateProfile} saving={saving}>
            {saving ? 'ENVIANDO...' : 'ENVIAR'}
          </Button>
        </>
      </CenterContainer>
    </MainContainer>
  );
}
