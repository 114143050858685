import { useCallback, useMemo } from 'react';
import { format, addDays } from 'date-fns';
import _ from 'lodash';

import {
  useFormValidate,
  useCurrency,
  useDate,
  useCookies,
} from 'modules/shared/hooks';
import {
  useRAQueryImmutable,
  useRAPostRequest,
} from 'modules/shared/hooks/requests';
import { useUserContext } from 'providers/UserProvider';

export default function useBookTrialCampaign() {
  const { user } = useUserContext();
  const { formatCurrency } = useCurrency();
  const { getHumanReadableDate, getHumanReadableDateFromTimestamp } = useDate();
  const {
    validPhone,
    validCpf,
    validPostalCode,
    validCreditCardHolderName,
    validCreditCardNumber,
    validCreditCardValidateDate,
    validCreditCardCvv,
  } = useFormValidate();
  const [cookies, setCookie] = useCookies([
    'credit_card_trial_duration_new',
    'credit_card_trial_plans_order',
    'credit_card_trial_without_default_plan',
    'credit_card_trial_random_default_plan',
  ]);

  const { data: getBookTrialResponse } = useRAQueryImmutable(
    '/campaigns/book_trials'
  );

  const createBookTrialWithCardReq = useRAPostRequest(
    '/campaigns/book_trials/create'
  );

  const bookTrialData = useMemo(() => {
    return getBookTrialResponse;
  }, [getBookTrialResponse]);

  const isOnTest01A = useMemo(() => {
    return (
      bookTrialData?.creditCard?.test === 'random_plan_order' &&
      user?.university?.isVarejao
    );
  }, [bookTrialData, user]);

  const testAbVersion = useMemo(() => {
    return null;
  }, []);

  const trialDays = useMemo(() => {
    return 7;
  }, []);

  const hasCreditCardTrial = useCallback(() => {
    return bookTrialData?.creditCard && bookTrialData.creditCard.hasTrial;
  }, [bookTrialData]);

  const showBookCreditCardTrialCTA = useCallback(() => {
    return (
      bookTrialData?.creditCard && bookTrialData.creditCard.needInsertCreditCard
    );
  }, [bookTrialData]);

  const showCheckoutLightDialog = useCallback(() => {
    return bookTrialData?.checkoutLight && bookTrialData.checkoutLight.eligible;
  }, [bookTrialData]);

  const bookCreditCardTrialFreeAccessBegin = useCallback(() => {
    return format(new Date(), 'dd/MM/yyyy');
  }, []);

  const bookCreditCardTrialFreeAccessEnd = useCallback(() => {
    if (hasCreditCardTrial()) {
      return getHumanReadableDateFromTimestamp(
        bookTrialData.creditCard.trialActiveUntil
      );
    }

    const endOfTrial = addDays(new Date(), trialDays - 1);
    return getHumanReadableDate(endOfTrial);
  }, [
    bookTrialData,
    getHumanReadableDate,
    getHumanReadableDateFromTimestamp,
    hasCreditCardTrial,
    trialDays,
  ]);

  const bookCreditCardTrialFirstCharge = useCallback(() => {
    if (hasCreditCardTrial()) {
      const expiresDate = new Date(
        bookTrialData.creditCard.trialActiveUntil * 1000
      );
      const firstChargeDate = addDays(expiresDate, 1);

      return getHumanReadableDate(firstChargeDate);
    }

    const chargeDate = addDays(new Date(), trialDays);
    return getHumanReadableDate(chargeDate);
  }, [bookTrialData, getHumanReadableDate, hasCreditCardTrial, trialDays]);

  const maxCancelationDate = useCallback(() => {
    const sixDaysFromNow = addDays(new Date(), trialDays - 1);
    return getHumanReadableDate(sixDaysFromNow);
  }, [getHumanReadableDate, trialDays]);

  const getBookCreditCardTrialAvailablePricings = useCallback(() => {
    const planPricings = bookTrialData?.creditCard?.planPricingsAvailable;
    if (!planPricings) return [];
    if (!isOnTest01A) return planPricings;

    if (cookies['credit_card_trial_plans_order'])
      return cookies['credit_card_trial_plans_order'];

    const newPlanPricingsOrder = _.shuffle(planPricings);
    setCookie(
      'credit_card_trial_plans_order',
      JSON.stringify(newPlanPricingsOrder)
    );
    return newPlanPricingsOrder;
  }, [bookTrialData, cookies, isOnTest01A, setCookie]);

  const getMonthlyPriceValue = useCallback(
    planPricing => {
      return formatCurrency(planPricing.price / planPricing.plan.months);
    },
    [formatCurrency]
  );

  const getUserDataErrors = useCallback(
    userData => {
      const phone = validPhone(userData.phone)
        ? ''
        : 'Número incorreto. Formato: (xx) xxxxx-xxxx';
      const cpf = validCpf(userData.cpf)
        ? ''
        : 'Por favor, preencha um CPF válido.';

      return { phone, name, cpf };
    },
    [validPhone, validCpf]
  );

  const getBillingAddressErrors = useCallback(
    billingAddress => {
      const postalCode = validPostalCode(billingAddress.postalCode)
        ? ''
        : 'Cep inválido. Por favor, insira o CEP corretamente (ex: 00000-000).';
      const district = billingAddress.district
        ? ''
        : 'Por favor, insira um bairro.';
      const street = billingAddress.street ? '' : 'Por favor, insira a rua.';
      const number = billingAddress.number ? '' : 'Por favor, insira o número.';
      const state = billingAddress.state
        ? ''
        : 'Por favor, selecione um estado.';
      const city = billingAddress.city
        ? ''
        : 'Por favor, selecione uma cidade.';

      return { postalCode, district, street, number, state, city };
    },
    [validPostalCode]
  );

  const getCreditCardErrors = useCallback(
    creditCard => {
      const number = validCreditCardNumber(creditCard.number)
        ? ''
        : 'Número de cartão inválido.';
      const holderName = validCreditCardHolderName(creditCard.holderName)
        ? ''
        : 'Nome inválido.';
      const expiry = validCreditCardValidateDate(creditCard.expiry)
        ? ''
        : 'Data inválida.';
      const cvv = validCreditCardCvv(creditCard.cvv)
        ? ''
        : 'Código de segurança inválido.';

      return { number, holderName, expiry, cvv };
    },
    [
      validCreditCardNumber,
      validCreditCardHolderName,
      validCreditCardValidateDate,
      validCreditCardCvv,
    ]
  );

  const createBookTrialWithCard = useCallback(
    async purchaseData => {
      let formattedPurchaseData = {
        ...purchaseData,
        days: trialDays,
      };

      try {
        await createBookTrialWithCardReq({
          purchaseData: formattedPurchaseData,
        });
        return { success: true };
      } catch (e) {
        return {
          success: false,
          message:
            e.response?.data?.message ||
            'Ocorreu um erro em nosso sistema. Por favor, entre em contato com o atendimento',
          errorType: e.response?.data?.error_type,
        };
      }
    },
    [createBookTrialWithCardReq, trialDays]
  );

  const getStates = useCallback(() => {
    return bookTrialData?.creditCard && bookTrialData.creditCard.states;
  }, [bookTrialData]);

  const getInitialPlanPricing = useCallback(planPricings => {
    return planPricings.find(p => p.plan.months === 12);
  }, []);

  const trialInfo = useMemo(() => {
    return bookTrialData?.creditCard && bookTrialData.creditCard.trialInfo;
  }, [bookTrialData]);

  const getInstallmentsMonthlyPrice = useCallback(
    (pricing, installments) => {
      return formatCurrency(pricing.price / installments);
    },
    [formatCurrency]
  );

  const getAvailableInstallmentsOptions = useCallback(
    planPricing => {
      const options = [];
      for (let index = 0; index < planPricing.plan.months; index++) {
        const installments = index + 1;
        const monthlyPrice = getInstallmentsMonthlyPrice(
          planPricing,
          installments
        );
        const text = `${installments} x R$ ${monthlyPrice}${
          installments > 1 ? '  / mês' : ''
        }`;
        options.push({ text, installments });
      }

      return options;
    },
    [getInstallmentsMonthlyPrice]
  );

  const testEventParams = useCallback(() => {
    // const testType = 'Teste Precificacao';
    // const testVariable = testAbVersion;
    // tests disabled for now

    return {};
  }, []);

  return {
    showBookCreditCardTrialCTA,
    bookCreditCardTrialFreeAccessBegin,
    bookCreditCardTrialFreeAccessEnd,
    bookCreditCardTrialFirstCharge,
    getBookCreditCardTrialAvailablePricings,
    getMonthlyPriceValue,
    getUserDataErrors,
    getStates,
    getBillingAddressErrors,
    getCreditCardErrors,
    createBookTrialWithCard,
    maxCancelationDate,
    hasCreditCardTrial,
    showCheckoutLightDialog,
    isOnTest01A,
    trialDays,
    testEventParams,
    getInitialPlanPricing,
    trialInfo,
    getAvailableInstallmentsOptions,
  };
}
