import { useCallback } from 'react';
import { useCookies } from 'modules/shared/hooks';

export default function useSkipBannerMaker(bannerSkipConditions = {}) {
  const [cookies, setCookie] = useCookies(['skipBannerData']);

  const updateBannerData = useCallback((bannerName, key) => {
    const bannerData = cookies.skipBannerData || {};
    const currentBanner = bannerData[bannerName] || {};
    bannerData[bannerName] = {
      ...currentBanner,
      [key]: (currentBanner[key] || 0) + 1,
    };

    setCookie('skipBannerData', JSON.stringify(bannerData), {
      path: '/',
      maxAge: 15 * 24 * 60 * 60, // 30 dias
    });
  }, []);

  const increaseBannerViewCount = useCallback(() => {
    updateBannerData(bannerSkipConditions?.bannerName, 'views');
  }, []);

  const increaseBannerClickCount = useCallback(() => {
    updateBannerData(bannerSkipConditions?.bannerName, 'clicks');
  }, []);

  const shouldSkipBannerMaker = useCallback(() => {
    const bannerData = cookies.skipBannerData || {};
    const bannerCountData = bannerData[bannerSkipConditions?.bannerName];

    return (
      bannerCountData?.views >= bannerSkipConditions?.views ||
      bannerCountData?.clicks >= bannerSkipConditions?.clicks
    );
  }, []);

  return {
    bannerName: bannerSkipConditions?.bannerName,
    increaseBannerClickCount,
    increaseBannerViewCount,
    shouldSkipBannerMaker,
  };
}
