import React, { useCallback, useMemo } from 'react';
import {
  SolvedBooks,
  ChangePlan,
  Covid19,
  UniversityPersonalizedContentBanner,
} from './banners/index.js';
import { DesktopBannerMaker } from '../shared/banners';
import { styled, media } from 'styles';
import ToggleableComponent from 'modules/shared/components/ToggleableComponent';
import { PaymentFailedRecoverBanner } from 'modules/campaigns/recover-subscriber/payment-failed-recover/banners';
import { FormerSubscriberRecoverBanner } from 'modules/campaigns/recover-subscriber/former-subscriber-recover/banners';
import { PerpetualPlansBanner } from 'modules/campaigns/perpetual-plans/banners';
import { useUserContext } from 'providers/UserProvider';
import { useBannerContext } from 'providers/BannerProvider';
import { useMvpBooksEligible } from 'modules/campaigns/mvp-books/hooks/useEligible.js';
import MvpThemesListBanner from 'modules/campaigns/mvp-books/banners/MvpThemesListBanner.js';
import useSkipBannerMaker from '../shared/hooks/useSkipBannerMaker.js';

export default function DesktopFrontBanner() {
  const { user } = useUserContext();
  const { bannersToShowInfo } = useBannerContext();
  const { isBannerEligibleInHomePage } = useMvpBooksEligible();

  const skipBannerData = useSkipBannerMaker({
    bannerName: 'IAG_25_1_Venda_Web',
    views: 9,
    clicks: 2,
  });
  const getPriorityBanner = useCallback(() => {
    if (
      bannersToShowInfo?.desktopFrontBannerName === 'paymentFailedRecoverBanner'
    )
      return <PaymentFailedRecoverBanner />;
    if (
      bannersToShowInfo?.desktopFrontBannerName ===
      'formerSubscriberRecoverBanner'
    )
      return <FormerSubscriberRecoverBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'changePlan')
      return <ChangePlan height="130px" />;
    if (
      bannersToShowInfo?.desktopFrontBannerName === 'desktopBannerMaker' &&
      !skipBannerData.shouldSkipBannerMaker()
    )
      return (
        <DesktopBannerMaker skipBannerData={skipBannerData} source="Home" />
      );
    if (bannersToShowInfo && isBannerEligibleInHomePage())
      return <MvpThemesListBanner origin={'Matérias'} utmContent="pag_home" />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'perpetualPlans')
      return <PerpetualPlansBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'personalizedContent')
      return <UniversityPersonalizedContentBanner />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'covid19')
      return <Covid19 height="160px" />;
    if (bannersToShowInfo?.desktopFrontBannerName === 'solvedBooks')
      return <SolvedBooks />;
    return null;
  }, [bannersToShowInfo, isBannerEligibleInHomePage]);

  const banner = useMemo(() => {
    return getPriorityBanner();
  }, [getPriorityBanner]);

  const canShowDesktopFrontBanner = useMemo(() => {
    return user && banner && media.isDesktop();
  }, [user, banner]);

  if (!canShowDesktopFrontBanner) return null;

  return (
    <ToggleableComponent
      renderDelay={1500}
      open={true}
      height={banner.props.height || '120px'}
    >
      <Container height={banner.props.height} id="desktop-front-banner">
        {banner}
      </Container>
    </ToggleableComponent>
  );
}

const Container = styled.div`
  width: 100%;
  height: ${({ height }) => (height ? height : '120px')};
  cursor: pointer;
`;
