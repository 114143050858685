import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SelectIsOnUniversity from './SelectIsOnUniversity';
import IsNotOnUniversityForm from './IsNotOnUniversityForm';
import IsOnUniversityForm from './IsOnUniversityForm';
import CourseInfoForm from './CourseInfoForm';
import AcademicEducationForm from './AcademicEducationForm';
import { useProfileComplete } from 'modules/profile/hooks';
import useProfileCompleteTracker from 'modules/profile/hooks/useProfileCompleteTracker';

export default function ProfileComplete() {
  const [step, setStep] = useState(null);
  const [isNFFOnLeft, setIsNFFOnLeft] = useState(null);
  const { userDontNeedSendCourseInfo } = useProfileComplete();
  const {
    shouldUserAcceptTerms,
    isUserOnUniversity,
    isUserNotOnUniversity,
    sendToCorrectUrl,
  } = useProfileComplete();
  const { trackViewProfileComplete } = useProfileCompleteTracker();

  useEffect(() => {
    const NFFOnLeft = Math.random() > 0.5;
    setIsNFFOnLeft(NFFOnLeft);

    trackViewProfileComplete(NFFOnLeft, userDontNeedSendCourseInfo);
  }, [trackViewProfileComplete, setIsNFFOnLeft, userDontNeedSendCourseInfo]);

  const steps = useMemo(() => {
    return {
      CHECK_IF_IS_ON_UNIVERSITY: 1,
      IS_ON_UNIVERSITY_FORM: 2,
      IS_NOT_ON_UNIVERSITY_FORM: 3,
      COURSE_INFO_FORM: 4,
      ACADEMIC_FORMATION_FORM: 5,
    };
  }, []);

  useEffect(() => {
    if (shouldUserAcceptTerms) {
      if (isUserNotOnUniversity)
        return setStep(steps.IS_NOT_ON_UNIVERSITY_FORM);
      else if (isUserOnUniversity) return setStep(steps.IS_ON_UNIVERSITY_FORM);
    } else if (isUserNotOnUniversity)
      return setStep(steps.ACADEMIC_FORMATION_FORM);
    else if (isUserOnUniversity && !userDontNeedSendCourseInfo)
      return setStep(steps.COURSE_INFO_FORM);
    setStep(steps.CHECK_IF_IS_ON_UNIVERSITY);
  }, [
    steps,
    setStep,
    shouldUserAcceptTerms,
    isUserNotOnUniversity,
    isUserOnUniversity,
    userDontNeedSendCourseInfo,
  ]);

  const afterSubmitIsOnUniversityForm = useCallback(
    university => {
      if (userDontNeedSendCourseInfo) return sendToCorrectUrl(university);
      setStep(steps.COURSE_INFO_FORM);
    },
    [userDontNeedSendCourseInfo, sendToCorrectUrl, setStep, steps]
  );

  const onChangeIsOnUniversity = useCallback(
    isOnUniversity => {
      if (isOnUniversity) setStep(steps.IS_ON_UNIVERSITY_FORM);
      else setStep(steps.IS_NOT_ON_UNIVERSITY_FORM);
    },
    [setStep, steps]
  );

  return (
    <>
      {step === steps.CHECK_IF_IS_ON_UNIVERSITY && (
        <SelectIsOnUniversity
          onChange={onChangeIsOnUniversity}
          isNFFOnLeft={isNFFOnLeft}
        />
      )}
      {step === steps.IS_NOT_ON_UNIVERSITY_FORM && (
        <IsNotOnUniversityForm
          afterSubmit={() => setStep(steps.ACADEMIC_FORMATION_FORM)}
          onBack={() => setStep(steps.CHECK_IF_IS_ON_UNIVERSITY)}
        />
      )}
      {step === steps.IS_ON_UNIVERSITY_FORM && (
        <IsOnUniversityForm
          afterSubmit={afterSubmitIsOnUniversityForm}
          onBack={() => setStep(steps.CHECK_IF_IS_ON_UNIVERSITY)}
        />
      )}
      {step === steps.COURSE_INFO_FORM && <CourseInfoForm />}
      {step === steps.ACADEMIC_FORMATION_FORM && (
        <AcademicEducationForm
          onBack={() => setStep(steps.IS_ON_UNIVERSITY_FORM)}
        />
      )}
    </>
  );
}
