import { useCallback, useMemo } from 'react';
import Cookies from 'js-cookie';
import { useLocation } from 'react-router-dom';
import { isStar, isTop50 } from 'providers/UniversityProvider';

import {
  useRAPostRequest,
  useRAQueryImmutable,
} from 'modules/shared/hooks/requests';
import { useCookies, useGTM } from 'modules/shared/hooks';
import { useUserContext } from 'providers/UserProvider';

export default function useProfileComplete() {
  const { addVariableToGTMDataLayer } = useGTM();
  const [cookies, setCookie] = useCookies(['justSignUp']);
  const sendCompleteProfile = useRAPostRequest('profile/complete_profile');
  const { data: userTerms, loading: userTermsLoading } = useRAQueryImmutable(
    '/profile/accepted_terms'
  );
  const { user } = useUserContext();
  const location = useLocation();

  const isAlreadyOnProfileCompletePage = useCallback(() => {
    return location.pathname.indexOf('profile-complete') !== -1;
  }, [location.pathname]);

  const userDontNeedSendCourseInfo = useMemo(() => true, []);

  const userCameFromPlaybook = useCallback(() => {
    return Cookies.get('came_from_pb') == 'true';
  }, []);

  const shouldRedirectToProfileComplete = useCallback(() => {
    if (userTermsLoading || !userTerms) return false;

    let shouldRedirectToProfileComplete =
      user && (!user.university || !userTerms?.acceptedTerms);

    if (location.pathname.includes('/lista-de-enunciado')) {
      shouldRedirectToProfileComplete = false;
    }
    const shouldRedirectToCourseInfoDialog =
      !shouldRedirectToProfileComplete &&
      user &&
      (!user?.course || !user?.yearAdmission || !user?.periodAdmission) &&
      user?.university?.name !== 'Não faço faculdade' &&
      !userDontNeedSendCourseInfo &&
      !userCameFromPlaybook();
    return (
      (shouldRedirectToProfileComplete || shouldRedirectToCourseInfoDialog) &&
      !isAlreadyOnProfileCompletePage()
    );
  }, [
    userTermsLoading,
    userTerms,
    user,
    location,
    userDontNeedSendCourseInfo,
    userCameFromPlaybook,
    isAlreadyOnProfileCompletePage,
  ]);

  const updateGtm = useCallback(
    async (university, campusId = '') => {
      addVariableToGTMDataLayer({
        iesAcronym: university?.acronym,
        iesId: university?.id,
        isStar: isStar(university?.id),
        isTop50: isTop50(university?.id),
      });

      const event = {
        event: 'completou_cadastro',
        iesID: university?.id,
        iesCampusID: campusId,
      };

      addVariableToGTMDataLayer(event);
    },
    [addVariableToGTMDataLayer]
  );

  const submitProfileComplete = useCallback(
    async (params, selectedUniversity) => {
      const res = { data: null, error: null };
      try {
        const { data } = await sendCompleteProfile(params);
        res.data = data;
        setCookie('justSignUp', cookies.justSignUp + 2);

        const universityCampusId = params?.university_campus_id;

        if (data.signup) updateGtm(selectedUniversity, universityCampusId);
      } catch (error) {
        res.error = error;
      }

      return res;
    },
    [updateGtm, setCookie, sendCompleteProfile, cookies]
  );

  const shouldUserAcceptTerms = useMemo(() => {
    if (userTermsLoading || !userTerms) return false;
    return !userTerms?.acceptedTerms;
  }, [userTermsLoading, userTerms]);

  const isUserOnUniversity = useMemo(() => {
    return (
      user?.university?.name && user?.university?.name !== 'Não faço faculdade'
    );
  }, [user]);

  const isUserNotOnUniversity = useMemo(() => {
    return user?.university?.name === 'Não faço faculdade';
  }, [user]);

  const getRedirectUrl = useCallback(
    (university = null) => {
      const query = new URLSearchParams(location.search);

      let data = {};

      for (let params of query.entries()) {
        data[params[0]] = params[1];
      }

      let eligibleUniversities = [
        338,
        507,
        233,
        639,
        666,
        172,
        339,
        130,
        665,
        371,
        49,
        505,
        106,
        147,
        148,
        548,
        483,
        237,
        325,
        231,
        195,
        570,
        667,
        571,
        324,
        372,
        26,
        215,
        1803,
        301,
        782,
        547,
        225,
        238,
        235,
        232,
        206,
        509,
        236,
        664,
        105,
        47,
        637,
        234,
        51,
        50,
        239,
        15,
        226,
        194,
        171,
        340,
        800,
        240,
        484,
        572,
        173,
        564,
        373,
        503,
        302,
        569,
        638,
        566,
        799,
        323,
        2720,
        5,
        136,
        25,
        213,
        567,
        609,
        341,
        546,
        2709,
        504,
        129,
        629,
        2737,
        214,
        14,
        303,
        205,
        230,
        610,
        1721,
        213,
        510,
        2501,
        300,
        20,
        341,
        783,
        375,
        565,
        1,
        228,
        568,
        19,
        229,
        48,
        107,
        146,
        227,
        216,
        2738,
        630,
        2787,
        2662,
      ];

      const targetDate = new Date(2025, 0, 20, 19, 0, 0); // January 20, 2025, at 19:00
      const now = new Date();

      if (data.seo === 'true' || data.home === 'true') {
        if (
          eligibleUniversities.includes(university?.id) &&
          now >= targetDate
        ) {
          return `http://www.respondeai.com.br/planos-iag-25-1?internal_source=${
            data.home === 'true' ? 'site' : 'seo'
          }`;
        }
      }

      return data.redirect ? data.redirect : '/';
    },
    [location.search]
  );

  const sendToCorrectUrl = useCallback(
    university => {
      window.location.href = decodeURIComponent(
        decodeURIComponent(getRedirectUrl(university))
      );
    },
    [getRedirectUrl]
  );

  return {
    shouldRedirectToProfileComplete,
    isAlreadyOnProfileCompletePage,
    submitProfileComplete,
    shouldUserAcceptTerms,
    isUserOnUniversity,
    isUserNotOnUniversity,
    sendToCorrectUrl,
    getRedirectUrl,
    userDontNeedSendCourseInfo,
  };
}
