import React, { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { styled, media } from 'styles';
import {
  TrialBar,
  GenericWarningBar,
  AutoRenewBar,
  NewYerWarningBanner,
} from './banners';
import CreditCardTrialWarningBar from 'modules/campaigns/book-trial/components/CreditCardTrialWarningBar';
import { useBannerContext } from 'providers/BannerProvider';
import { useHeader } from 'modules/shared/hooks';

export default function WarningBanner() {
  const { bannersToShowInfo } = useBannerContext();
  const location = useLocation();
  const { shouldHideHeader } = useHeader();

  const getPriorityBanner = useCallback(() => {
    if (bannersToShowInfo?.warningBannerName === 'creditCardTrialBar')
      return <CreditCardTrialWarningBar />;
    if (bannersToShowInfo?.warningBannerName === 'trialBar')
      return <TrialBar />;
    if (bannersToShowInfo?.warningBannerName === 'autoRenewBar')
      return <AutoRenewBar />;
    if (bannersToShowInfo?.warningBannerName === 'emergencyBanner')
      return <GenericWarningBar />;

    return null;
  }, [bannersToShowInfo]);

  if (shouldHideHeader(location.pathname)) return null;
  return <Container className="warning-bar">{getPriorityBanner()}</Container>;
}

const Container = styled.div`
  width: 100%;
  font-family: Lato;

  font-size: 0.8rem;
  line-height: 0.9rem;

  @media ${media.mobile} {
    display: none;
  }
`;
